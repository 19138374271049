<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitlePartnersMobile' : 'mainTitlePartners'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" no-gutters>
      <h3 v-bind:class="mobile ? 'subTitlePartnersMobile' : 'subTitlePartners'">{{ $t('subTitle') }}</h3>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-img
          :src="require('@/assets/picto/separator3.svg')"
          contain
          v-bind:height="mobile ? 100 : 150"
          max-width="80%"
      />
    </v-row>
    <v-row v-if="mobile" justify="center" no-gutters>
      <v-col cols="5" :key="index" v-for="(partner, index) in all">
        <v-img
            :src="partner.image"
            :alt="partner.title"
            contain
            width="150"
        />
      </v-col>
    </v-row>
    <v-row v-if="!mobile" justify="space-between" class="contentPartners" no-gutters>
      <v-img
          v-for="(partner, index) in sectionsFirstLine"
          :key="index"
          :src="partner.image"
          :alt="partner.title"
          height="100%"
          max-width="150"
      />
    </v-row>
    <br>
    <br>
    <v-row v-if="!mobile" justify="space-between" class="contentPartners" no-gutters>
      <v-img
          v-for="(partner, index) in sectionsSecondLine"
          :key="index"
          :src="partner.image"
          :alt="partner.title"
          height="100%"
          max-width="150"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PartnersComp',
  props: ['mobile'],
  data: () => ({
    all: [],
    sectionsFirstLine: [
      {
        image: require('@/assets/partners/cnsa.png'),
        title: 'CNSA',
      },
      {
        image: require('@/assets/partners/caissedepargne.png'),
        title: "Caisse d'épargne",
      },
      {
        image: require('@/assets/partners/departement06.png'),
        title: 'Département Alpes Maritimes',
      },
      {
        image: require('@/assets/partners/assuranceMaladie.png'),
        title: 'Assurance maladie',
      },
      {
        image: require('@/assets/partners/mutualiteFrancaise.png'),
        title: 'Mutualité Française',
      },
      {
        image: require('@/assets/partners/argirEtArco.png'),
        title: 'agrirc et arrco',
      },
      {
        image: require('@/assets/partners/msa.png'),
        title: 'MSA',
      }
    ],
    sectionsSecondLine: [
      {
        image: require('@/assets/partners/associationPACA.png'),
        title: 'Association santé PACA',
      },
      {
        image: require('@/assets/partners/carsat.png'),
        title: "Carsat",
      },
      {
        image: require('@/assets/partners/ars.png'),
        title: 'ARS',
      },
      {
        image: require('@/assets/partners/lesPep.png'),
        title: 'Les pep',
      },
      {
        image: require('@/assets/partners/djscs.png'),
        title: 'Direction régionale de la jeunesse des sports et de la cohésion sociale',
      },
      {
        image: require('@/assets/partners/isatis.png'),
        title: 'isatis',
      },
      {
        image: require('@/assets/partners/asDuCoeur.png'),
        title: 'AS du coeur',
      }
    ]
  }),
  created() {
    this.$set(this, 'all', this.sectionsFirstLine.concat(this.sectionsSecondLine));
  }
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Partners",
    "subTitle": "They trust us"
  },
  "fr": {
    "mainTitle": "Partenaires",
    "subTitle": "Ils nous font confiance"
  }
}
</i18n>

<style scoped>

.mainTitlePartners {
  padding-top: 30px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.subTitlePartners {
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.mainTitlePartnersMobile {
  padding-top: 80px;
  font-size: 25px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.subTitlePartnersMobile {
  font-size: 16px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.contentPartners {
  padding-right: 150px;
  padding-left: 150px;
}

</style>
