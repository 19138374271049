<template>
  <div>
    <PartnersComp v-bind:mobile="mobile" />
  </div>
</template>

<script>
import PartnersComp from '@/components/desktop/vitaeSportSante/PartnersComp'

  export default {
    name: 'Partners',
    props: ['mobile'],
    components: {
      PartnersComp
    },
  }
</script>
